<template>
  <main-modal
      :active="active"
      @onClose="onClose"
      :bodyPadding="'0 60px'">
    <template v-slot:modalHeader>
      <a-button
          @click="onClose()"
          class="back-btn button-without-animation">
        <i class="anticon"><arrow/></i>
        {{ $t('bases.bases_list') }}</a-button>
    </template>
    <template v-slot:modalBody>
      <base-form
          @onClose="onClose"/>
    </template>
  </main-modal>
</template>

<script>
import MainModal from '@/components/MainModal/MainModal'
import arrow from '@/assets/icons/back.svg?inline'
import BaseForm from '@/views/admin/BaseForm/BaseForm'

export default {
  name: 'CreateBases',
  props: ['active'],
  components: {
    arrow,
    MainModal,
    BaseForm
  },
  methods: {
    onClose() {
      this.$store.commit('detailBase/SET_BASE_DETAIL', {})
      this.$store.commit('detailBase/SET_BASE_ORGANIZATION_DATA', {})
      this.$emit('closeModal', false)
      this.$emit('updateList')
    }
  }
}
</script>


