<template>
  <div class="base-form-wrapp" style="height: auto;">
    <a-tabs default-active-key="1" @change="activeTab = $event">
      <a-tab-pane key="general" :tab="$t('basics.general_tab')">
        <div class="detail-info__name" v-if="!isEmptyDetail" :class="{'not-active': baseStatus === 3}">
          <h2 class="title">Base {{detailBase.short_alias}} (#{{detailBase.base_id}})
            <button class="delete-btn" @click="showMoreInfo = !showMoreInfo"><i class="anticon"><more-icon/></i></button>
          </h2>
          <div v-if="showMoreInfo" class="detail-info__more">
            <a href="javascript:void(0);" class="detail-info__more--delete" @click="deleteBase">{{ $t('bases.delete_base') }}</a>
          </div>
        </div>
        <div v-if="showMoreInfo" class="detail-info__more--overlay" @click="showMoreInfo = !showMoreInfo"></div>
        <a-form :form="form" class="base-form " :class="{'create-form': isEmptyDetail}">
          <a-row :class="{'not-active': baseStatus === 3}">
            <a-col :lg="12">
              <a-form-item label="Base ID *">
                <a-input
                    size="large"
                    :disabled="!isEmptyDetail && detailBase.id > 0"
                    v-decorator="['base_id', {rules: [
                      {required: true, message: $t('messages.requiredField')},
                      {min: 3, message: $t('messages.minLength') + 3},
                      {max: 255, message: $t('messages.maxLength') + 255}],
                      initialValue: !isEmptyDetail ? detailBase.base_id : ''}]">
                </a-input>
              </a-form-item>
              <a-form-item label="Description">
                <a-textarea
                    size="large"
                    :autoSize="{ minRows: 4, maxRows: 4 }"
                    v-decorator="['description', {rules: [
                      {max: 255, message: $t('messages.maxLength') + 255}],
                      initialValue: detailBase.description}]">
                </a-textarea>
              </a-form-item>
              <a-form-item label="Short alias" v-if="getRole === 1">
                <a-input
                    size="large"
                    aria-placeholder="B12345"
                    v-decorator="['short_alias', {initialValue: !isEmptyDetail ? detailBase.short_alias : ''}]"
                >
                </a-input>
              </a-form-item>
              <a-form-item label="Long alias">
                <a-input
                    size="large"
                    v-decorator="['long_alias', {initialValue: !isEmptyDetail ? detailBase.long_alias : ''}]"
                >
                </a-input>
              </a-form-item>
              <a-form-item class="one-row large-item" :label="`${$t('basics.status_title')}  ${detailBase.is_active ? $t('basics.status.active'): $t('basics.status.inactive')}`">
                <a-switch @change="detailBase.is_active = !detailBase.is_active" v-decorator="['is_active',
                 {valuePropName: 'checked', initialValue: !isEmptyDetail ? Boolean(detailBase.is_active) : false}]" />
              </a-form-item>
              <a-form-item class="one-row large-item" :label="$t('bases.maintenance_mode')">
                <a-switch @change="detailBase.maintenance_mode = !detailBase.maintenance_mode" v-decorator="['maintenance_mode',
                 {valuePropName: 'checked', initialValue: !isEmptyDetail ? Boolean(detailBase.maintenance_mode) : false}]" />
              </a-form-item>
              <a-form-item class="one-row large-item" :label="$t('bases.auth_local')" v-if="getRole === 1">
                <a-switch @change="detailBase.auth_local = !detailBase.auth_local" v-decorator="['auth_local',
                {valuePropName: 'checked', initialValue: !isEmptyDetail ? Boolean(detailBase.auth_local) : false}]" />
              </a-form-item>
            </a-col>
            <a-col :lg="12">

              <a-form-item v-if="getRole <= 2" :label="$t('bases.linked_organization')">
                <a-select
                    size="large"
                    :disabled="getRole == 2 && detailBase.id > 0"
                    :placeholder="$t('bases.no_organization')"
                    @change="handleSelectChanges"
                    v-decorator="['organization_id',{rules: [
                      {required: false, message: $t('messages.requiredField')}],
                      initialValue: !isEmptyDetail && !!detailBase.organization ? detailBase.organization.id : undefined}]">
                  <a-select-option value="no_organization">
                    {{ $t('bases.no_organization') }}
                  </a-select-option>
                  <a-select-option v-for="item in organizationsDropdownListWithoutLink" :value="item.id" :key="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :label="$t('bases.location')">
                <a-input
                    size="large"
                    disabled
                    v-decorator="['address', {initialValue: getOrganizationDetails.location ? getOrganizationDetails.location.label : null}]">
                </a-input>
              </a-form-item>
              <a-form-item :label="$t('bases.managers_full_name')">
                <a-input
                    size="large"
                    disabled
                    v-decorator="['manager', {initialValue: getOrganizationDetails.manager}]">
                </a-input>
              </a-form-item>
              <a-form-item label="Email">
                <a-input
                    size="large"
                    disabled
                    v-decorator="['email', {initialValue: getOrganizationDetails.email}]">
                </a-input>
              </a-form-item>
              <a-form-item :label="$t('basics.phone_number')">
                <a-input
                    size="large"
                    disabled
                    v-decorator="['phone', {initialValue: getOrganizationDetails.phone}]">
                </a-input>
              </a-form-item>
              <a-form-item v-if="!isEmptyDetail && baseStatus !== 3" :label="$t('bases.base_qr_code') + ':'">
                <img :src="detailBase.qr_code_url" class="download-img" alt="qr_code_url">
                <router-link
                  :to="{ name: 'QRcodePage',
                        query: { qrLink: detailBase.qr_code_url }}"
                        target="_blank"
                  style="color: #40454E;"
                >
                  <div class="download-btn">{{ $t('bases.download_png') }} <i class="anticon"><download-icon /></i></div>
                </router-link>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div class="form-action-btn">
          <a-button
              @click="$emit('onClose')"
          >{{ $t('basics.cancel') }}</a-button>
          <a-button
              type="primary"
              @click="handleSubmit"
              :loading="loginBtn"
              :disabled="loginBtn || baseStatus === 3">{{ $t('bases.save_base') }}</a-button>
        </div>
      </a-tab-pane>
      <a-tab-pane key="advanced" :tab="$t('basics.advanced_tab')">
        <div class="buttons-control">
          <a-button @click="updateDetails" :disabled="updateDetailsDisabled">{{ $t('basics.update') }}</a-button>
          <a-button :disabled="rebootDisabled" @click="showRebootConfirmation = true">{{ $t('basics.reboot') }}</a-button>
          <a-button :disabled="!showUnlockAllPicosButton" @click="showUnlockAllPicosConfirmation = true">{{ $t('picos.unlock_all') }}</a-button>
        </div>
        <div class="last-updated" v-if="detailBase.api_updated_at">{{ $t('bases.last_updated') }}: {{ lastUpdatedAt }}</div>
        <div class="scroll-container" style="width: 100%;height: calc(100vh - 370px);overflow: auto; margin-top: 50px;">
          <div class="table-container" style="display: table; width: fit-content;">
            <a-table
                :locale={emptyText:emptyText}
                class="main-table"
                :columns="columns"
                :data-source="advancedData"
                :pagination="false"
                rowKey="position"
            />
          </div>
        </div>

        <a-modal
            v-model="showUnlockAllPicosConfirmation"
            wrapClassName="warning-modal"
            :width="570"
            :footer="false"
            @cancel="showUnlockAllPicosConfirmation = false"
        >
          <div class="wrapper">
            <div class="warning-messages">
              <p>{{ `${$t('bases.unlock_all_picos_confirm')} ${detailBase.base_id}` }}</p>
            </div>
          </div>
          <div class="btn-wrapper">
            <a-button @click="showUnlockAllPicosConfirmation = false">{{ $t('basics.cancel') }}</a-button>
            <a-button class="left-btn ant-btn ant-btn-primary" @click="unlockAllPicos">Ok</a-button>
          </div>
        </a-modal>
        <a-modal
            v-model="showRebootConfirmation"
            wrapClassName="warning-modal"
            :width="570"
            :footer="false"
            @cancel="showRebootConfirmation = false"
        >
          <div class="wrapper">
            <div class="warning-messages">
              <p>{{ `${$t('bases.reboot_confirm')} ${detailBase.base_id}` }}</p>
            </div>
          </div>
          <div class="btn-wrapper">
            <a-button @click="showRebootConfirmation = false">{{ $t('basics.cancel') }}</a-button>
            <a-button class="left-btn ant-btn ant-btn-primary" @click="reboot">Ok</a-button>
          </div>
        </a-modal>
 
      </a-tab-pane>
      <a-tab-pane key="firmware" v-if="getRole === 1" :tab="$t('basics.firmware_tab')">
        <h2 class="subtitle">{{ $t('bases.base_informations') }}</h2>
        <a-row class="my-bot-50px">
          <a-col :lg="6">
            <div class="base-info-wrapper">
              <p><span class="tech-title">{{ $t('bases.hardware_type') }}: </span>{{ baseType }}</p>
            </div>
          </a-col>
        </a-row>
        <h2 class="subtitle">{{ $t('bases.pico_version') }}</h2>
<!--        :form="form"-->
        <a-form class="base-form">
          <a-row>
            <a-col :lg="6">
              <a-form-item :label="$t('bases.pico_versions')">
                <a-select
                    size="large"
                    :placeholder="$t('bases.no_organization')"
                    v-model="picosVersion"
                >
                  <a-select-option v-for="item in picoVersions" :value="item.id" :key="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-button
                  type="primary"
                  :loading="loginBtn"
                  :disabled="!picosVersion || picosVersion === firmwareInfo.version"
                  @click="changePicosVersion"
              >
                {{ $t('bases.change_version') }}
              </a-button>
            </a-col>
          </a-row>
        </a-form>

        <a-row class="my-50px">
          <a-col :lg="6">
            <div class="firmware-info-wrapper">
              <h2 class="subtitle">{{ $t('bases.firmware_info') }}</h2>
              <p><span class="tech-title">{{ $t('bases.firmware_version') }}: </span>{{ baseFirmware }}</p>
              <p><span class="tech-title">{{ $t('bases.mac_wifi') }}: </span>{{ firmwareMacWifi }}</p>
              <p><span class="tech-title">{{ $t('bases.mac_ethernet') }}: </span>{{ firmwareMacEth }}</p>
              <p><span class="tech-title">{{ $t('bases.ip_address') }}: </span>{{ ipAddress }}</p>
            </div>
          </a-col>
          <a-col :lg="6">
            <a-form class="base-form">
              <h2 class="subtitle">{{ $t('bases.auto_reboot') }}</h2>
              <a-form-item class="one-row large-item" :label="$t('bases.auto_reboot')">
                <a-switch @change="autoReboot.enable = !autoReboot.enable" />
              </a-form-item>

              <div class="auto-reboot-wrapper">
                <a-time-picker
                    :value="autoReboot.time ? moment(autoReboot.time, 'HH:mm') : null"
                    size="large"
                    format="HH:mm"
                    style="width: 100%"
                    :disabled="!autoReboot.enable"
                    @change="autoReboot.time = $event"
                />
              </div>

              <a-button
                  type="primary"
                  :loading="loginBtn"
                  :disabled="!autoReboot.enable || !autoReboot.time"
                  @click="setAutoReboot"
              >
                {{ $t('basics.save_changes') }}
              </a-button>
            </a-form>
          </a-col>
          <a-col :lg="2"></a-col>
          <a-col :lg="6">
            <a-form class="base-form">
              <h2 class="subtitle">{{ $t('bases.screensaver') }}</h2>
              <a-form-item class="one-row large-item" :label="$t('bases.screensaver')">
                <a-switch @change="screensaverState = !screensaverState"/>
              </a-form-item>

              <a-button
                  type="primary"
                  :loading="loginBtn"
                  :disabled="loginBtn"
                  @click="setScreensaver"
              >
                {{ $t('basics.save_changes') }}
              </a-button>
            </a-form>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import moment from 'moment';
import moreIcon from '@/assets/icons/More.svg?inline'
import downloadIcon from '@/assets/icons/download.svg?inline'
import {requiredField, minLength, maxLength, deleteBaseMassages} from '@/utils/messages';
import {advancedUpdate, advancedSync, unlockAllPicos} from '@/api/bases';

export default {
  name: 'BaseForm',
  data() {
    return  {
      baseDetailsLoaded: false,
      activeTab: 'general',
      updateDetailsDisabled: false,
      rebootDisabled: false,
      showRebootConfirmation: false,
      showUnlockAllPicosConfirmation: false,
      slotReleasing: false,
      requiredField,
      minLength,
      maxLength,
      loginBtn: false,
      showMoreInfo: false,
      detailOrganizationData: {},
      emptyText: this.$t('picos.no_data'),
      columns: [
        {
          title: '#',
          dataIndex: 'position',
          key: 'position',
        },
        {
          title: 'Pico id',
          dataIndex: 'pico_id',
          key: 'pico_id'
        },
        {
          title: 'Pico alias',
          dataIndex: 'alias',
          key: 'alias'
        },
        {
          title: 'Version',
          dataIndex: 'version',
          key: 'version'
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status'
        },
        {
          title: 'SOH',
          dataIndex: 'SOH',
          key: 'SOH'
        },
        {
          title: 'SOC',
          dataIndex: 'SOC',
          key: 'SOC'
        },
        {
          title: 'Voltage',
          dataIndex: 'Voltage',
          key: 'Voltage'
        },
        {
          title: 'PowerIn',
          dataIndex: 'powerIn',
          key: 'powerIn'
        },
        {
          title: 'VoltageIn',
          dataIndex: 'VoltageIn',
          key: 'VoltageIn'
        },
        {
          title: 'Issues',
          dataIndex: 'issues',
          key: 'issues'
        },
        {
          title: 'Temp',
          dataIndex: 'temp',
          key: 'temp'
        },
        {
          title: 'Cell',
          dataIndex: 'cell',
          key: 'cell'
        },
        {
          title: 'Current',
          dataIndex: 'Current',
          key: 'Current'
        },
        {
          title: 'Cable',
          dataIndex: 'cable',
          key: 'cable'
        },
        {
          title: 'lenLog',
          dataIndex: 'lenLog',
          key: 'lenLog'
        },
        {
          title: 'Balance',
          dataIndex: 'balance',
          key: 'balance'
        },
        {
          title: 'Available',
          dataIndex: 'available',
          key: 'available'
        },
        {
          title: 'Release slot',
          key: 'release',
          customRender: pico => {
            return pico.locker !== '' ? this.$createElement("a-button", {
              props: {
                type: "primary",
                loading: this.loginBtn,
                disabled: this.slotReleasing
              },
              on: {
                'click': () => {
                  this.$confirm({
                    centered: true,
                    content: this.$t('messages.releasePico'),
                    okText: this.$t('basics.yes'),
                    cancelText: this.$t('basics.cancel'),
                    width: 490,
                    closable: true,
                    onOk: () => {
                      this.$store.dispatch('detailBase/ReleaseSlot', {
                        id: this.detailBase.id,
                        slot: pico.locker
                      })
                          .then(res => {
                            this.$message.success(this.$t('bases.slot_released'))
                            this.$store.dispatch('detailBase/DeleteBaseDetailPico', pico.position);
                            this.showMoreInfo = false
                          })
                    },
                    onCancel: () => this.showMoreInfo = false
                  })
                },
              },
              slot: 'default'
            }, 'Unlock ' + pico.locker + ' slot') : '';
          },
        }
      ],
      picosVersion: null,
      screensaverState: false,
      autoReboot: {
        enable: false,
        time: null
      }
    }
  },
  components: {
    moreIcon,
    downloadIcon
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  mounted() {
    let modal = document.getElementsByClassName('modal-body')[0];
    if (modal) {
      modal.addEventListener('scroll', this.handleScroll);
    }
  },
  computed: {
    advancedData() {
      const positions = [
        {position: 1},
        {position: 2},
        {position: 3},
        {position: 4},
        {position: 5},
        {position: 6},
        {position: 7},
        {position: 8},
        {position: 9},
        {position: 10}
      ]

      return this.detailBase.picos ? positions.map(position => {
        const pico = this.detailBase.picos.find(pico => {
          return pico.tech_info && parseInt(pico.tech_info.locker) === position.position;
        });

        let data;

        if(pico){
          data = pico.tech_info;
          if ( typeof(pico.tech_info.cell) == 'object' ) {
            data.cell = pico.tech_info.cell && pico.tech_info.cell.length ? ('[ ' + pico.tech_info.cell.join(', ') + ' ]') : null;
          }
          if ( typeof(pico.tech_info.temp) == 'object' ) {
            data.temp = pico.tech_info.temp && pico.tech_info.temp.length ? ('[ ' + pico.tech_info.temp.join(', ') + ' ]') : null;
          }
        }else if(this.detailBase.lockers && this.detailBase.lockers[position.position]) {
          data = {
            pico_id: '?',
            spot: '?',
            acceleroMax: '?',
            SOH: '?',
            SOC: '?',
            powerIn: '?',
            lenLog: '?',
            available: '?',
            issues: '?',
            temp: '?',
            cell: '?',
            cable: '?',
            version: '?',
            status: '?',
            locker: position.position,
            Current: '?',
            Voltage: '?',
            VoltageIn: '?',
            balance: '?',
          }
        }else{
          data = {
            pico_id: '',
            spot: '',
            acceleroMax: '',
            SOH: '',
            SOC: '',
            powerIn: '',
            lenLog: '',
            available: '',
            issues: '',
            temp: '',
            cell: '',
            cable: '',
            version: '',
            status: '',
            locker: '',
            Current: '',
            Voltage: '',
            VoltageIn: '',
            balance: '',
          }
        }

        data.position = position.position;

        return data;
      }) : []
    },
    getRole() {
      return parseInt(this.$store.getters['getRole']);
    },
    getOrganizationId() {
      return this.$store.getters['getOrganizationId']
    },
    getOrganizationDetails() {
      return this.$store.getters['detailOrganization/getOrganizationDetails']
    },
    organizationsList() {
      return this.$store.getters['organizations/getOrganizations']
    },
    organizationsDropdownListWithoutLink() {
      return this.$store.getters['organizations/getOrganizationsWithoutLink']
    },
    detailBase() {
      return this.$store.getters['detailBase/getBaseDetails']
    },
    baseStatus() {
      return parseInt(this.$store.getters['detailBase/getBaseStatus'])
    },
    getBaseOrganizationData() {
      return this.$store.getters['detailBase/getBaseOrganizationData']
    },
    isEmptyDetail() {
      return Object.keys(this.detailBase).length === 0
    },
    lastUpdatedAt() {
      if (!this.detailBase.api_updated_at) return null;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Date(this.detailBase.api_updated_at)
          .toLocaleString('en-GB', {timeZone: timezone})
          .replaceAll('/', '.');
    },
    showUnlockAllPicosButton() {
      if (!this.detailBase.lockers) return false;

      return Object.values(this.detailBase.lockers).some(el => !!el);
    },
    firmwareInfo() {
      return this.detailBase ? this.detailBase.firmware : null;
    },
    picoVersions() {
      return this.detailBase && this.firmwareInfo && this.firmwareInfo.pico_bin
          ? this.firmwareInfo.pico_bin.map(el => {
            return {id: el, name: el};
          })
          : [];
    },
    firmwareMacWifi() {
      return this.firmwareInfo?.mac_wifi ?? '-';
    },
    firmwareMacEth() {
      return this.firmwareInfo?.mac_eth ?? '-';
    },
    ipAddress() {
      return this.firmwareInfo?.ip_address ?? '-';
    },
    baseType() {
      return this.firmwareInfo?.type ?? '-';
    },
    baseFirmware() {
      return this.firmwareInfo?.version ?? '-';
    },
  },
  watch: {
    activeTab(name) {
      const modalBody = document.getElementsByClassName('modal-body')[0];

      if (name === 'advanced') {
        modalBody.style.overflow = 'hidden';
      } else {
        modalBody.style.overflow = 'auto';
      }
    },
    detailBase(data) {
      if (data) {
        this.baseDetailsLoaded = true;
        this.picosVersion = null;
      }
    },
    baseDetailsLoaded(val) {
      if (val) {
        this.listenBaseUpdate();
      }
    }
  },
  methods: {
    moment,
    handleScroll () {
      let openedSelects = document.getElementsByClassName('ant-select ant-select-open');
      let selectDropdowns = document.getElementsByClassName('ant-select-dropdown');

      openedSelects.forEach(element => {
        if (element) {
          element.classList.remove(...['ant-select-open', 'ant-select-focused']);
        }
      })

      selectDropdowns.forEach(element => {
        if (element) {
          element.style.display = 'none';
        }
      })
    },
    updateDetails() {
      this.updateDetailsDisabled = true;
      advancedUpdate(this.detailBase.id)
      this.$message.success(this.$t('bases.update_request_sent'))
    },
    reboot() {
      this.rebootDisabled = true;
      this.showRebootConfirmation = false;
      advancedSync(this.detailBase.id);
      this.$message.success(this.$t('bases.reboot_request_sent'))
    },
    unlockAllPicos() {
      this.showUnlockAllPicosConfirmation = false;
      unlockAllPicos(this.detailBase.id).then(() => {
        this.$store.dispatch('detailBase/ClearBaseDetailPicos');
      });
      this.$message.success(this.$t('bases.all_picos_unlocked'));
    },
    downloadQR() {
      const img = new Image();
      const canvas = document.createElement('canvas');

      img.crossOrigin = 'anonymous';
      img.src = this.detailBase.qr_code_url;
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        const context = canvas.getContext('2d');

        context.drawImage(img, 0, 0, img.width, img.height);

        canvas.toBlob(function (blob) {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'qr_code.png');
          document.body.appendChild(link);
          link.click();
        });
      }
    },
    listenBaseUpdate() {
      this.$echo.private(`base.${this.detailBase.id}`)
      .listen('.base_pico_list_updated', () => {
      	this.updateDetailsDisabled = false;
      	this.rebootDisabled = false;
        this.$store.dispatch('detailBase/GetBase', this.detailBase.id);
      });
    },
    handleSelectChanges(value) {
      if (value === "no_organization") {
        this.form.setFieldsValue({
          address: '',
          email: '',
          manager: '',
          phone: ''
        });
        return;
      }
      this.$store.dispatch('detailOrganization/GetOrganization', value)
      .then(res => {
        this.form.setFieldsValue({
          address: res.data.address,
          email: res.data.email,
          manager: res.data.manager,
          phone: res.data.phone,
        });
      })
    },
    changePicosVersion() {
      this.$store.dispatch('bases/ChangePicosVersion', {baseId: this.detailBase.id, version: this.picosVersion})
          .then(() => {
            this.$message.success(this.$t('login.request_was_sent'));
          });
    },
    setAutoReboot() {
      // console.log(moment(this.autoReboot.time).format('H:mm'));
      // this.autoReboot.time = moment(this.autoReboot.time, ['h:m a', 'H:m']);
      this.$store.dispatch('bases/SetAutoReboot', {
        baseId: this.detailBase.id,
        time: moment(this.autoReboot.time).format('H:mm')
      })
          .then(() => {
            this.$message.success(this.$t('login.request_was_sent'));
          })
          .catch(error => {
            console.log({error});
            // for (const [key, _] of Object.entries(error.response.data.error.errors)) {
            //   this.autoRebootErrors[key] = true;
            // }
          });
    },
    setScreensaver() {
      this.$store.dispatch('bases/SetScreensaver', {baseId: this.detailBase.id, enable: this.screensaverState})
          .then(() => {
            this.$message.success(this.$t('login.request_was_sent'));
          });
    },
    handleSubmit () {
      this.loginBtn = true
      this.form.validateFields((err, values) => {
        if (values.organization_id === "no_organization") {
          values.organization_id = null;
        }
        let params = {
          organization_id: values.organization_id,
          base_id: values.base_id,
          long_alias: values.long_alias,
          description: values.description,
          is_active: values.is_active,
          maintenance_mode: values.maintenance_mode,
          auth_local: values.auth_local,
        }
        if (this.getRole === 1) {
          params.short_alias = values.short_alias
        }
        if (!err) {
          if (this.isEmptyDetail) {
            this.$store.dispatch( 'detailBase/CreateBase', params)
                .then(() => {
                  this.$message.success('Base is created successfully')
                  this.loginBtn = false
                  this.$emit('onClose');
                })
                .catch(error => {
                  for (const [key, value] of Object.entries(error.response.data.error.errors)) {
                    this.form.setFields({
                      [key]: {
                        value: '',
                        errors: [new Error(value)],
                      },
                    });
                  }
                  this.loginBtn = false
                })
          } else {
            this.$store.dispatch( 'detailBase/UpdateBase', {
              id: this.detailBase.id,
              data: params
            })
                .then(() => {
                  this.$message.success('Base is updated successfully')
                  this.$emit('onClose');
                  this.loginBtn = false
                })
                .catch(error => {
                  for (const [key, value] of Object.entries(error.response.data.error.errors)) {
                    this.form.setFields({
                      [key]: {
                        value: '',
                        errors: [new Error(value)],
                      },
                    });
                  }
                  this.loginBtn = false
                })
          }
        } else {
          this.loginBtn = false
        }
      })
    },
    deleteBase() {
      this.$confirm({
        centered: true,
        content: this.$t('messages.deleteBaseMassages'),
        okText: this.$t('basics.yes'),
        cancelText: this.$t('basics.cancel'),
        width: 490,
        closable: true,
        onOk: () => {
          this.$store.dispatch('detailBase/DeleteBase', this.detailBase.id)
              .then(() => {
                this.$message.success(this.$t('bases.base_deleted'))
                this.$emit('onClose')
              })
              .catch(error => {
                this.$message.error(error.response.data.errors);
              })
        },
        onCancel: () => this.showMoreInfo = false
      })
    },
  },
  beforeDestroy() {
    this.$echo.leave(`base.${this.detailBase.id}`);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style lang="less" scoped>
.download-img {
  display: block;
  margin: 5px 0;
  width: 74px;
  height: 74px;
}

.download-btn {
  display: inline-block;
  cursor: pointer;

  .anticon {
    vertical-align: middle;
  }
}

.buttons-control{
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.last-updated {
  margin-top: 10px;
  font-size: 12px;
}

.base-form {
  .ant-form-item {
    max-width: none;
    margin: auto auto 24px;
  }
}

.main-table {
  padding-bottom: 20px;
 }

.main-table::v-deep {
  .ant-table-body {
    .ant-table-tbody {
      .ant-table-row {
        td {
          white-space: nowrap;
        }
      }
    }
  }
}

.warning-modal {
  .ant-modal-body {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .wrapper {
    p {
      margin-top: 20px;
      text-align: center;
    }
  }

  .btn-wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    .left-btn {
      margin-left: 50px;
    }
  }
}

.my-50px {
  margin: 50px 0;
}

.my-bot-50px {
  margin-bottom: 50px;
 }

.base-info-wrapper {
  .tech-title {
    opacity: 0.5;
  }
}

.firmware-info-wrapper {
  .tech-title {
    opacity: 0.5;
  }
}

.auto-reboot-wrapper {
  display: flex;
  margin-bottom: 24px;
  padding-right: 20px;
}

.ant-form-item-control {
  .error-msg {
    position: absolute;
    display: block;
    bottom: -27px;
    color: #FF5D53;
    font-size: 12px;
  }
}
</style>


