<template>
  <div class="main-content">
    <div class="main-table-wrapper main-content-wrapp" :class="{'modal-opened': visibleModal}">
      <div class="main-table-block">
        <list-heading>
          <list-title>{{ $t('bases.bases_list') }}</list-title>
            <div class="action-wrapper">
                <a-button
                    v-if="getRole === 2"
                    @click="pushNfcBadges()"
                    :disabled="pushNfcBtnDisabled"
                >
                    {{ $t('bases.push_nfc') }}
                </a-button>
                <a-button
                    type="primary"
                    class="orange-btn"
                    style="margin-left: 20px"
                    @click="setActiveModal(!visibleModal)">
                    {{ $t('bases.add_base') }}
                </a-button>
            </div>


        </list-heading>
        <div class="admin-layout__list-filters">
          <search
              :placeholderText="searchPlaceholder"
              @select="searchInList"></search>
          <div class="admin-layout__filters">
            <table-size-changer
                :defaultPageSize="pageSize"
                @select="setPageSize"/>
            <list-filter
                :organizations="organizationsDropdownList"
                :checkboxes="filterCheckboxes"
                with-empty-organization
                @filterBy="filterList"
                filteredType="Base"
                label="Organization"
            />
          </div>
        </div>
        <a-table
            :locale={emptyText:emptyText}
            class="main-table"
            :loading="loading"
            :columns="getRole === 1 ? columnsSuperAdmin : columnsAdmin"
            rowKey="id"
            :pagination="false"
            :data-source="basesList"
            @change="handleTableChange"
            :scroll="{y: 'calc(100% - 40px)' }"
        >
          <template slot="hardware_type" slot-scope="text, record">
            {{record.type || '-'}}
          </template>
          <template slot="short_alias" slot-scope="text, record">
            {{record.short_alias || '-'}}
          </template>
          <template slot="long_alias" slot-scope="text, record">
            {{record.long_alias || '-'}}
          </template>
          <template slot="organization_name" slot-scope="text, record" v-if="getRole === 1">
            {{!!record.organization ? record.organization.name : '-'}}
          </template>
          <template slot="organization_email" slot-scope="text, record" v-if="getRole === 1">
            {{!!record.organization ? record.organization.email : '-'}}
          </template>
          <template slot="status" slot-scope="text, record">
            <div class="status online-status"
              :class="{
                'is-active': record.online_status.id === 1,
                'is-low-activity': record.online_status.id === 2,
                'is-offline': record.online_status.id === 3,
                'is-off': record.online_status.id === 4}"
            >
                <span v-if="record.online_status.id === 1">{{$t('bases.status.active')}}</span>
                <span v-if="record.online_status.id === 2">{{$t('bases.status.low_activity')}}</span>
                <span v-if="record.online_status.id === 3">{{$t('bases.status.offline')}}</span>
                <span v-if="record.online_status.id === 4">{{$t('bases.status.off')}}</span>
                <span v-if="record.online_status.updated_at" class="updated">
                  {{ $t('basics.updated') }}: {{ getClientTime(record.online_status.updated_at) }}
                </span>
            </div>
          </template>
          <template slot="action" slot-scope="text, record">
            <a-button
                @click="setActiveModal(true, record.id)"
                type="primary">
              {{ $t('organizations.details') }}
            </a-button>
          </template>
        </a-table>
      </div>
      <pagination
          @change="setPageNumber"
          :defaultPageNumber="pageNumber"
          :paginationData="pagination"
      />
    </div>
    <create-bases
        class="create-bases"
        @updateList="fetchData"
        @closeModal="setActiveModal"
        :active="visibleModal"/>
  </div>
</template>

<script>
import _ from 'lodash';
import { formatDate } from '@/utils/helpers/dateFormat';
import ListTitle from '@/components/ListTitle/ListTitle'
import ListHeading from '@/components/ListHeading/ListHeading'
import Search from '@/components/Search/Search'
import TableSizeChanger from '@/components/TableSizeChanger/TableSizeChanger'
import ListFilter from '@/components/ListFilter/ListFilter'
import Pagination from '@/components/Pagination/Pagination'
import CreateBases from '@/views/admin/CreateBases/CreateBases'
import { defaulVeriables } from '@/utils/defaulVeriables';
import { i18n } from '@/i18n';
import {pushBadges} from "../../../api/organizations";

const columnsSuperAdmin = [
  {
    title: 'Base ID',
    dataIndex: 'base_id',
    key: 'base_id',
    sorter: true,
    scopedSlots: { customRender: 'base_id' },
  },
  {
    title: i18n.t('bases.hardware_type'),
    dataIndex: 'hardware_type',
    key: 'hardware_type',
    sorter: false,
    scopedSlots: { customRender: 'hardware_type' }
  },
  {
    title: 'Short alias',
    dataIndex: 'short_alias',
    key: 'short_alias',
    sorter: false,
    scopedSlots: { customRender: 'short_alias' }
  },
  {
    title: 'Long alias',
    dataIndex: 'long_alias',
    key: 'long_alias',
    sorter: false,
    scopedSlots: { customRender: 'long_alias' }
  },
  {
    title: i18n.t('bases.linked_organization'),
    dataIndex: 'organization_name',
    key: 'organization_name',
    sorter: true,
    scopedSlots: { customRender: 'organization_name' }
  },
  {
    title: i18n.t('basics.status_title'),
    dataIndex: 'status',
    key: 'status',
    width: '15%',
    sorter: false,
    scopedSlots: { customRender: 'status' }
  },
  {
    dataIndex: 'action',
    key: 'action',
    width: '15%',
    scopedSlots: { customRender: 'action' }
  }
];
const columnsAdmin = [
  {
    title: 'Base ID',
    dataIndex: 'base_id',
    key: 'base_id',
    width: '20%',
    sorter: true,
    scopedSlots: { customRender: 'base_id' },
  },
  {
    title: 'Short alias',
    dataIndex: 'short_alias',
    key: 'short_alias',
    width: '20%',
    sorter: false,
    scopedSlots: { customRender: 'short_alias' },
  },
  {
    title: 'Long Alias',
    dataIndex: 'long_alias',
    key: 'long_alias',
    width: '20%',
    sorter: false,
    scopedSlots: { customRender: 'long_alias' },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '50%',
    sorter: false,
    scopedSlots: { customRender: 'status' }
  },
  {
    dataIndex: 'action',
    key: 'action',
    width: '15%',
    scopedSlots: { customRender: 'action' }
  }
];

export default {
  name: 'BasesList',
  components: {
    TableSizeChanger,
    ListTitle,
    ListHeading,
    Search,
    ListFilter,
    Pagination,
    CreateBases
  },
  data() {
    return {
      formatDate,
      loading: false,
      searchPlaceholder: i18n.t('bases.search_placeholder'),
      columnsSuperAdmin,
      columnsAdmin,
      filterCheckboxes: defaulVeriables,
      visibleModal: false,
      endpoint: 'GetBasesList',
      emptyText: i18n.t('bases.empty_text'),
      pushNfcBtnDisabled: false
    }
  },
  props: {
    placeholderText: {
      type: String,
      required: false,
      default: ''
    },
    defaultPageSize: {
      type: Number,
      required: false,
      default: null
    },
    defaultPageNumber: {
      type: Number,
      required: false,
      default: null
    },
    paginationData: {
      type: Object,
      required: false,
      default: null
    },
    dataSource: {
      type: Array,
      required: false,
      default: null
    },
    organizations: {
      type: Array,
      required: false,
      default: null
    },
    checkboxes: {
      type: Array,
      required: false,
      default: null
    },
    columnsData: {
      type: Array,
      required: false,
      default: null
    },
    emptyTableText: {
      type: String,
      required: false,
      default: i18n.t('bases.no_data')
    }
  },
  mounted() {
    this.fetchData();
    this.$store.dispatch('organizations/GetOrganizationDropdownList');
  },
  computed: {
    basesList() {
      return this.$store.getters['bases/getBasesTableData'];
    },
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    },
    pageSize() {
      return this.$store.getters['bases/getPageSize'];
    },
    pageNumber() {
      return this.$store.getters['bases/getPageNumber'];
    },
    pagination() {
      const { meta } = this.$store.getters['bases/getBasesList']
      return meta ? meta : {}
    },
    organizationsDropdownList() {
      return this.$store.getters['organizations/getOrganizations']
    },
    getOrganizationsWithoutLink() {
      return this.$store.getters['organizations/getOrganizationsWithoutLink']
    },
    organizationDetail() {
      return this.$store.getters['detailOrganization/getOrganizationDetails'];
    }
  },
  methods: {
    fetchData(value) {
      this.loading = true;
      this.$store.dispatch('bases/GetBasesList', value ? value : {})
          .then(()=> { this.loading = false })
    },
    setPageSize(value) {
      this.$store.commit('bases/SET_PAGE_SIZE', value)
      this.fetchData()
    },
    setPageNumber(value) {
      this.fetchData({page: value})
    },
    filterList(params) {
      this.$store.commit('bases/SET_FILTERS', params);
      this.fetchData({params})
    },
    handleTableChange(pagination, filters, sorter) {
      var sortOrderName = sorter.order === 'ascend' ? 'asc' : 'desc',
          sortParams = {sortField: sorter.field, sortOrder: sortOrderName};

      sortParams = sorter.order ? sortParams : {}
      this.$store.commit('bases/SET_SORT', sortParams)
      this.fetchData(sortParams)
    },
    searchInList: _.debounce(function (value) {
      value = encodeURIComponent(value);
      this.$store.commit('bases/SET_SEARCH_VALUE', value);
      if(value.trim().length >= 3) {
        this.fetchData({ search: value })
      } else if(value === '') {
        this.fetchData()
      }
    }, 700),
    setActiveModal(value, itemID) {
      if (!value) {
        this.$store.commit('detailOrganization/SET_ORGANIZATIONS_DETAIL', {})        
      }
      this.visibleModal = value
      if (itemID) {
        this.$store.dispatch('detailBase/GetBase', itemID)
      } else if (value && !itemID) {
        if (this.getRole === 2) {
          if (typeof  this.organizationDetail.id === 'undefined') {
            if (this.organizationsDropdownList.length > 0) {
              this.$store.dispatch('detailOrganization/GetOrganization', this.organizationsDropdownList[0].id)
            }
          }
        }
        setTimeout(() => {
          this.$store.commit('detailBase/SET_BASE_DETAIL', {})
        }, 1000);
      }
    },
    getClientTime(time) {
      if (!time) return null;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Date(time)
          .toLocaleString('en-GB', {timeZone: timezone});
    },
    pushNfcBadges() {
      if (this.getRole === 2) {
        this.pushNfcBtnDisabled = true;
        for(var key in this.getOrganizationsWithoutLink) {
          pushBadges(this.getOrganizationsWithoutLink[key].id);
        }
        this.$message.success(this.$t('bases.push_nfc_request_sent'))
      }
    }
  }
}
</script>

<style lang="less" scoped>
.create-bases::v-deep {
  .modal-body {
    max-width: none !important;
  }
}

.online-status {
  & .updated {
    display: block;
  }
}
</style>


